canvas-container {
  height: 40vh;
  width: 70vw;
}

.App-header {
  background-color: var(--color-background);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--color-text-primary);
}

.App-link {
  color: var(--color-secondary);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} 

/* ------------------------------------------------------------- */

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-background);
  padding: 0 20px;
}

.header, .footer, .menu, .submenu-content, .bottom-menu {
  background-color: var(--color-background) !important;
}

.logo {
  display: flex;
  align-items: center;
}

.menu {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.menu-item {
  color: var(--color-text-primary);
  padding: 0 15px;
  transition: color 0.3s;
}

.menu-item:hover {
  color: var(--color-hover-primary);
}

.avatar {
  border-radius: 50%;
  margin-right: 20px;
}

.bottom-menu {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--color-background);
  color: var(--color-text-primary);
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 8px var(--color-border-light);
  overflow-x: auto;
  z-index: 1000; /* Para garantir que fique acima de outros elementos */
}

.bottom-menu .menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-text-primary);
}

.bottom-menu .menu-item span {
  font-size: 12px;
}

.submenu-button {
  background-color: var(--color-background);
  color: var(--color-text-primary);
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  width: 100%; /* Full width for better touch target */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px; /* Add some space above the button */
}

.submenu-button:hover {
  background-color: var(--color-secondary);
}

.submenu-content {
  background-color: var(--color-background);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 8px var(--color-border-dark);
  margin-top: 10px;
}

.submenu-item {
  color: var(--color-text-primary);
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  align-items: center;
}

.submenu-item:hover {
  background-color: var(--color-secondary);
  color: var(--color-text-primary);
  border-radius: 5px;
}

.submenu-item svg {
  margin-right: 8px; /* Space between icon and text */
}

.btn-primary {
  background-color: var(--color-background);
  color: var(--color-text-primary);
}

.btn-primary:hover {
  background-color: var(--color-secondary);
}

@media (max-width: 768px) {
  .header {
    justify-content: center;
  }

  .menu {
    display: none;
  }

  .bottom-menu {
    display: flex;
    position: fixed;
    bottom: 0; /* Fixo na parte inferior */
    width: 100%;
    background-color: var(--color-background);
    justify-content: space-around;
    padding: 20px 0; /* Aumenta a altura do menu */
    box-shadow: 0 -2px 8px var(--color-border-light);
    overflow-x: auto;
    z-index: 1000; /* Para garantir que fique acima de outros elementos */
  }

    .content {
    padding: 10px;
  }

  .ant-tabs {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Suaviza o scroll em iOS */
  }

  .ant-tabs-tab {
    flex: 1 0 auto; /* Permite que as abas rolem horizontalmente */
    text-align: center;
    padding: 10px 5px;
  }

  .ant-table {
    font-size: 14px; /* Aumenta a legibilidade */
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 8px;
  }

  .ant-btn {
    width: 100%;
    margin-bottom: 10px;
    font-size: 16px;
  }

  .ant-form-item {
    margin-bottom: 15px;
  }
}

@media (min-width: 769px) {
  .bottom-menu {
    display: none;
  }

  .menu {
    display: flex;
  }
}


/* -------------------------------------------------------------
                         DIVERSOS                          
------------------------------------------------------------- */
.content {
  background-color: var(--color-card-background);
  padding: 1rem;
  height: 900px;
  overflow-y: auto;
}

.label-Login {
  color: var(--color-text-primary) !important;
}

.login-form-forgot {
  color: var(--color-text-primary) !important;
}

.texto {
  color: var(--color-background);
}

.Logo-Menu {
  text-align: center;
  color: var(--color-text-primary);
  font-size: 20px;
}

.content-NotFound {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-NotFound {
  text-align: center;
}

.faixa-destaque {
  position: relative;
  background-color: var(--color-background);
  /* Cor de fundo roxa */
  color: var(--color-text-primary);
  /* Cor do texto branco */
  padding: 15px;
  /* Espaçamento interno */
  text-align: center;
  /* Alinhamento do texto centralizado */
  font-size: 18px;
  /* Tamanho da fonte */
  font-weight: bold;
  /* Negrito */
  border-radius: 8px;
  /* Borda arredondada */
  box-shadow: 0 2px 4px var(--color-border-light);
  /* Sombra sutil */
  margin-bottom: 20px;
  /* Espaçamento inferior */
}

.faixa-destaque a {
  color: var(--color-text-primary);
  /* Cor do texto branco */
}

.fechar {
  position: absolute;
  top: 50%;
  right: 10px;
  /* Ajuste para deixar um pequeno espaço entre o botão e a borda */
  transform: translateY(-50%);
  background-color: transparent;
  color: var(--color-text-primary);
  border: none;
  cursor: pointer;
  font-size: 20px;
}

.fechar:hover {
  color: var(--color-secondary);
  /* Mudança de cor no hover */
}

:where(.css-dev-only-do-not-override-d2lrxs) a {
  color: var(--color-background);
}:where(.css-dev-only-do-not-override-d2lrxs) a:hover {
  color: var(--color-secondary);
}

/* -------------------------------------------------------------
  TABLE
------------------------------------------------------------- */
.font-destaque {
  font-weight: bold;
}

.cor-destaque-backgroud {
  background-color: lightgrey;
  font-weight: bold;
}

.cor-positiva {
  color: var(--color-background);
}

.cor-negativa {
  color: red;
}

/* -------------------------------------------------------------
  COMPONENTES ANTD
------------------------------------------------------------- */
/* Override Ant Design Menu Background Color to var(--color-background) */
.ant-menu,
.ant-menu-dark,
.ant-menu-horizontal {
    background-color: var(--color-background) !important;
}

.ant-menu-item:hover,
.ant-menu-item-active {
    background-color: var(--color-secondary) !important;
    color: var(--color-text-primary) !important;
}

.ant-menu-item-selected {
    background-color: var(--color-secondary) !important;
    color: var(--color-text-primary) !important;
}

/* .btn-primary {
  background-color: var(--color-background);
}
.btn-primary:hover {
  background-color: var(--color-secondary) !important;
}:where(.css-dev-only-do-not-override-d2lrxs).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: var(--color-secondary);
  border-color: var(--color-secondary);
}

btn-default {
  background: var(--color-text-primary);
}:where(.css-dev-only-do-not-override-1rqnfsa).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: var(--color-secondary);
  border-color: var(--color-secondary);
  background: var(--color-text-primary);
}

.ant-menu {
  background: var(--color-background) !important;
}

.ant-menu-dark {
  background: var(--color-background) !important;
}

.ant-menu-overflow {
  background-color: var(--color-background) !important;
}

.ant-menu-overflow-item {
  background-color: var(--color-background) !important;
}

.ant-menu-item {
  background-color: var(--color-background) !important;
}

.ant-menu-submenu {
  background-color: var(--color-background) !important;
}

.ant-layout-sider-children {
  background-color: var(--color-background) !important;
}

.ant-layout-sider-trigger {
  background-color: var(--color-background) !important;
}

.ant-steps-item-icon {
  background-color: var(--color-background) !important;
  border-color: var(--color-background) !important;
}

.ant-tabs-tab ant-tabs-tab-active {
  background-color: var(--color-background) !important;
}:where(.css-dev-only-do-not-override-mzwlov).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color-background) !important;
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-layout-sider-zero-width-trigger ant-layout-sider-zero-width-trigger-left {
  background: var(--color-background) !important;
}:where(.css-dev-only-do-not-override-mzwlov).ant-layout .ant-layout-sider-zero-width-trigger {
  background: var(--color-background) !important;
}

.ant-tabs-tab-btn {
  color: var(--color-background) !important;
}

.ant-switch-inner {
  background-color: var(--color-background) !important;
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-picker-outlined:hover {
  border-color: var(--color-secondary);
}

.ant-picker-input{
  border-color: var(--color-secondary);
}:where(.css-dev-only-do-not-override-d2lrxs).ant-picker .ant-picker-input >input {
  border-color: var(--color-secondary);
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-tabs .ant-tabs-tab:hover {
  color: var(--color-secondary) !important;
}

.anticon {
  color: var(--color-text-primary);
} */