:root {
  /* Cores principais */
  --color-primary: #FFD700; /* Amarelo do sol (botões, destaques) */
  --color-secondary: #236ecf; /* Azul claro para hover e links */
  --color-background: #1a1a1a; /* Fundo cinza escuro (menu, header, footer) */
  --color-header: #282832; /* Fundo do cabeçalho */
  --color-footer: #282832; /* Fundo do rodapé */
  --color-text-primary: #FFFFFF; /* Branco para texto principal */
  --color-text-secondary: #CCCCCC; /* Cinza claro para texto secundário */

  /* Cores adicionais */
  --color-card-background: #fafafa; /* Fundo claro para cards */
  --color-table-header: #fafafa; /* Fundo claro para cabeçalhos de tabelas */
  --color-table-row-odd: #333333; /* Fundo alternado de linhas ímpares em tabelas */
  --color-table-row-even: #282828; /* Fundo alternado de linhas pares em tabelas */
  --color-positive: #28a745; /* Verde para valores positivos */
  --color-negative: red; /* Vermelho para valores negativos */

  /* Cores de hover e bordas */
  --color-hover-primary: #1890ff; /* Azul vibrante no hover */
  --color-border-light: rgba(0, 0, 0, 0.1); /* Borda clara (sombra leve) */
  --color-border-dark: rgba(0, 0, 0, 0.2); /* Borda escura (sombra mais intensa) */

  /* Espaçamentos */
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 32px;

  /* Fontes */
  --font-family: 'Arial', sans-serif;
}
